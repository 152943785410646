
import { defineComponent, onMounted } from 'vue'
import TabsTitle from '@/components/TabsTitle.vue'
import SecondFooter from '@/components/SecondFooter.vue'
import { filter } from '@/hooks/Imooks'
import { routGo } from '@/hooks/links'

export default defineComponent({
  name: 'Price',
  components: {
    TabsTitle,
    SecondFooter
  },
  setup () {
    const rollAssignMent = (domName: string) => {
      return document.getElementById(domName)?.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
    onMounted(() => {
      _hmt.push(['_trackPageview', '/price'])
    })
    return {
      filter,
      rollAssignMent,
      routGo
    }
  }
})
