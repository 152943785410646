function useThousandSign (num: number | string): string {
  const myNum = num.toString().split('.')
  const arr = myNum[0].split('').reverse()
  let res: any = []
  for (let i = 0, len = arr.length; i < len; i++) {
    if (i % 3 === 0 && i !== 0) {
      res.push(',')
    }
    res.push(arr[i])
  }
  res.reverse()
  if (myNum[1]) {
    res = res.join('').concat('.' + myNum[1])
  } else {
    res = res.join('')
  }
  return res
}

export default useThousandSign
