
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import useThousandSign from '@/hooks/useThousandSign'
import axios from 'axios'
type Norms = {
  bhu: string
  bhuMonthPrice: string
  bhuPrice: string
  class: string
  detail: string
  monthPrice: string
  name: string
  oneYearPrice: string
  price: string
  threeYearPrice: string
  twoYearPrice: string
}
interface Active {
  check: number,
  change: (index: number) => void,
  standard: Norms[],
  premium: Norms[],
  ultimate: Norms[]
}
export default defineComponent({
  name: 'TabsTitle',
  setup () {
    const active = reactive<Active>({
      check: 1,
      change: (index:number): void => {
        active.check = index
      },
      standard: [],
      premium: [],
      ultimate: []
    })

    onMounted(() => {
      const url = '/2/api/v1/idp-shop/product/website-list'
      axios.get(url).then(res => {
        console.log(res.data.data.res)
        const [standard, premium, ultimate] = res.data.data.res
        active.standard = standard.products
        active.premium = premium.products
        active.ultimate = ultimate.products
      })
    })

    return {
      ...toRefs(active),
      useThousandSign
    }
  }
})
